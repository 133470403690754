<template>
  <div id="recordDialog">
    <el-dialog
      title="关联订单"
      :visible.sync="show"
      width="720px"
      @open="getRecord"
    >
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column label="关联订单" align="center">
          <template slot-scope="scope">
            <span class="orderId" @click="goToPage(scope.row.orderId)">{{
              scope.row.orderId
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="总金额" align="center">
        </el-table-column>
        <el-table-column prop="couponPrice" label="优惠券金额" align="center">
        </el-table-column>
        <el-table-column prop="payAmount" label="应付金额" align="center">
        </el-table-column>
        <el-table-column prop="walletAmount" label="钱包支付" align="center">
        </el-table-column>
        <el-table-column prop="orderStatus" label="订单状态" align="center">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      parkingRecordId: "",
      table: {
        tableData: [],
        loading: false,
      },
    };
  },
  computed: {
    menuList() {
      return this.$store.state.controlAuthority.menuList;
    },
  },
  methods: {
    goToPage(orderId) {
      if (this.menuList.includes("/stopOrder/list")) {
        this.$router.push({
          name: "stopOrderList",
          params: {
            keyword: orderId,
          },
        });
      }
    },
    async getRecord() {
      try {
        this.table.loading = true;
        let res = await this.$http.get(
          `/parking/record/list/${this.parkingRecordId}/order`
        );
        if (res.code === 0) {
          this.table.tableData = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#recordDialog {
  .el-dialog {
    max-height: calc(100vh - 30vh);
    overflow-y: auto;
  }
  .orderId {
    cursor: pointer;
  }
}
</style>
